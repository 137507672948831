import React from 'react';
import { Link, Tooltip } from '@mui/material';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Avatar } from 'src/components/Avatar';
import { WorkerNameCellProps } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import {
  AVATAR_BG_COLOR,
  AVATAR_SHADOW,
  AVATAR_SIZE,
  AVATAR_VARIANT,
} from 'src/components/Avatar/types';

export const WorkerNameCell: React.FC<WorkerNameCellProps> = ({
  name,
  handleOpenWorkerProfile,
  image,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={handleOpenWorkerProfile}
      sx={{
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'transform 0.4s',
        '&:hover': {
          transform: 'scale(1.10)',
        },
      }}>
      <Box mr={1}>
        <Link>
          <Tooltip title={''}>
            <Avatar
              alt="name"
              bgColor={AVATAR_BG_COLOR.DARK}
              src={image}
              variant={AVATAR_VARIANT.ROUNDED}
              size={AVATAR_SIZE.LG}
              shadow={AVATAR_SHADOW.SM}
            />
          </Tooltip>
        </Link>{' '}
      </Box>
      <Typography
        variant="caption"
        fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
        color={TYPOGRAPHY_COLORS.TEXT}>
        {name}
      </Typography>
    </Box>
  );
};
