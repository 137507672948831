/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Card, CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { Header } from 'src/pages/Profile/components/Header';
import { Footer } from 'src/components/Footer';
import { WorkerProfileInfoCard } from 'src/components/Cards/WorkerProfileInfoCard';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useGetWorkerById } from 'src/api/hooks/useWorker';
import { useGetInterviewHash } from 'src/api/hooks/useInterview';
import { HashAttributes } from '../Job/components/EmptyJobView';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { useWorkerActions } from 'src/hooks/useWorkerActions';

export const WorkerProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const {
    isLoading,
    resendWorkerInvite,
    acceptApplication,
    declineApplication,
    terminateWorker,
    removeWorkerFromCompany,
  } = useWorkerActions();

  const history = useHistory();

  const [workerProfile, setWorkerProfile] = useState<WorkerProfile | null>(null);
  const [hashAttributes, setHashAttributes] = useState<HashAttributes>();

  const applicationId = workerProfile?.applications?.[0]?.id;
  const jobId = workerProfile?.jobs?.[0]?.id;

  const [getWorker, { loading: getWorkerLoading }] = useGetWorkerById({
    onComplete: res => {
      if (res) {
        setWorkerProfile(res.worker);
      }
    },
  });

  const [getInterviewHash] = useGetInterviewHash({
    onComplete: res => {
      if (res) {
        setHashAttributes(res);
      }
    },
  });

  const handleWorkerDocumentsNavigation = (workerData: WorkerProfile) => {
    history.push({
      pathname: `/worker/${workerData.id}/documents`,
      state: { workerId: workerData.id },
    });
  };

  useEffect(() => {
    getInterviewHash();
  }, []);

  useEffect(() => {
    getWorker({ variables: { id } });
  }, [id]);

  if (getWorkerLoading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      {workerProfile && (
        <Header
          imageUrls={workerProfile?.imageUrls}
          title={getWorkerNameWithAge(workerProfile)}
          subTitle={workerProfile ? workerProfile.phoneNumber : ''}
          loading={isLoading}
          actions={[
            ...(workerProfile?.poolStatus === POOL_STATUS.PENDING_OFFER
              ? [
                  {
                    label: t('workersPage.actions.resendInvite.title'),
                    action: () => resendWorkerInvite({ variables: { workerId: id } }),
                  },
                  {
                    label: t('workersPage.actions.removeWorkerFromCompany.title'),
                    action: () => removeWorkerFromCompany({ variables: { workerId: id } }),
                  },
                ]
              : workerProfile?.poolStatus === POOL_STATUS.NEW_APPLICATION
              ? [
                  {
                    label: t('workersPage.actions.acceptApplication.title'),
                    action: () =>
                      applicationId && acceptApplication({ variables: { applicationId } }),
                  },
                  {
                    label: t('workersPage.actions.declineApplication.title'),
                    action: () =>
                      applicationId && declineApplication({ variables: { applicationId } }),
                  },
                ]
              : workerProfile?.poolStatus === POOL_STATUS.ACTIVE
              ? [
                  {
                    label: t('workersPage.actions.terminateWorker.title'),
                    action: () =>
                      jobId && terminateWorker({ variables: { workerId: id, jobId: jobId } }),
                  },
                ]
              : []),
            {
              label: t('workerProfile.documents.documents.title'),
              action: () => handleWorkerDocumentsNavigation(workerProfile),
            },
          ]}
          style={{ marginInline: '5px' }}
        />
      )}
      <Box mt={5} mb={3}>
        {workerProfile && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <WorkerProfileInfoCard worker={workerProfile} />
            </Grid>
            {!!workerProfile.interviewId && hashAttributes && (
              <Grid item xs={12} md={6}>
                <Card>
                  <Box pt={2} px={2}>
                    <Typography
                      variant="h6"
                      fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                      textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                      <Trans>workerProfile.interview</Trans>
                    </Typography>
                  </Box>
                  <Box height="400px">
                    <iframe
                      width="100%"
                      height="100%"
                      allowFullScreen
                      src={`https://embed.myinterview.com/player.v3.html?apiKey=xkqnejs448zhbgzqnuqljpm5&hashTimestamp=${hashAttributes.timestamp}&hash=${hashAttributes.hash}&video=${workerProfile.interviewId}&fs=0`}></iframe>
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Footer />
    </DashboardLayout>
  );
};
