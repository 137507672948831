import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
} from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { CreateJobOfferModal } from './components/CreateJobOfferModal';
import {
  useGetJobOffers,
  useGetJobById,
  useGetJobApplications,
  useCancelJobApplication,
  useAcceptJobApplication,
  useTerminateJob,
  useRemoveLanguages,
  useGenerateFacebookPost,
} from 'src/api/hooks/useJob';
import { JobOffer } from 'src/DTO/JobOffer.type';

import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';
import { RemoveWorkersFromJobDialog } from './components/RemoveWorkersFromJobDialog';
import { CreateJobDialog } from 'src/pages/Job/components/CreateJobDialog';
import { EditModes } from './Job.types';
import { JOB_OFFER_STATUS } from 'src/DTO/JobOfferStatus.enum';
import { HashAttributes } from './components/EmptyJobView';
import { useGetInterviewHash } from 'src/api/hooks/useInterview';
import { ApplicationModal } from './components/ApplicationModal';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { WorkForceViewContent } from './components/WorkForceViewContent';
import { getLanguage } from 'src/utils/getLanguage';
import { useLocalizedData } from 'src/utils/useLocalizeData';
import { LanguageRemoval } from 'src/components/LanguageRemoval';
import { LanguageKeys } from 'src/components/LanguageSelectorContext/types';
import { ModalChatGPT } from 'src/components/ModalChatGPT';
import { JobDetailPageParams } from './JobDetail.types';
import { JobDetails } from 'src/components/JobDetails/JobDetails';

export const JobDetailPage: React.FC = () => {
  const history = useHistory();

  const { jobId } = useParams<JobDetailPageParams>();
  const { t } = useTranslation();

  const [getJobOffers, { data: allJobOffers }] = useGetJobOffers();
  const [getJobApplications, { data: jobApplications }] = useGetJobApplications();
  const [getJobById, { data: job, loading }] = useGetJobById();
  const { enJob, huJob } = useLocalizedData(job);
  const [selectedLanguages, setSelectedLanguages] = useState<Record<LanguageKeys, boolean>>({
    hu: false,
    en: false,
  });
  const [modalText, setModalText] = useState<string>('');
  const [modalImage, setModalImage] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  const [shouldGenerateText, setShouldGenerateText] = useState<boolean>(false);
  const [shouldGenerateImage, setShouldGenerateImage] = useState<boolean>(false);

  const [textGenerationLoading, setTextLoading] = useState<boolean>(false);
  const [imageGenerationLoading, setImageLoading] = useState<boolean>(false);

  const handleGenerationOptions = (shouldGenerateText: boolean, shouldGenerateImage: boolean) => {
    setShouldGenerateText(shouldGenerateText);
    setShouldGenerateImage(shouldGenerateImage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGenerationSuccess = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t('jobPage.GenerationModal.generationSuccess'),
    });
  };

  const handleGenerationFailure = () => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('jobPage.GenerationModal.generationError'),
    });
  };

  const [generateFbPost] = useGenerateFacebookPost({
    onComplete: res => {
      setShowModal(true);
      if (res) {
        if (res.texts) {
          setModalText(res.texts);
          setTextLoading(false);
        }
        if (res.images) {
          setModalImage(res.images);
          setImageLoading(false);
        }
        handleGenerationSuccess();
      }
    },
    onError: () => {
      setTextLoading(false);
      setImageLoading(false);
      handleGenerationFailure();
    },
  });

  const [terminateJob, { loading: terminateLoading }] = useTerminateJob({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('jobPage.jobHasBeenTerminated'),
      });

      history.push({
        pathname: '/job',
      });
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.jobTerminationFailed'),
      });
    },
  });

  const [cancelApplication, { loading: cancelApplicationLoading }] = useCancelJobApplication({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('jobPage.removeWorkersFromJobDialog.cancelJobApplicationSuccess'),
      });

      setIsApplicationModalOpen(false);
      getJobById({ variables: { jobId } });
      getJobApplications({ variables: { jobId } });
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.removeWorkersFromJobDialog.cancelJobApplicationFailed'),
      });
    },
  });
  const [acceptApplication, { loading: acceptApplicationLoading }] = useAcceptJobApplication({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('jobPage.removeWorkersFromJobDialog.acceptJobApplicationSuccess'),
      });

      setIsApplicationModalOpen(false);
      getJobById({ variables: { jobId } });
      getJobApplications({ variables: { jobId } });
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.removeWorkersFromJobDialog.acceptJobApplicationFailed'),
      });
    },
  });
  const [removeLanguage] = useRemoveLanguages({
    onComplete: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('jobPage.languageHasBeenRemoved'),
      });

      history.push({
        pathname: '/job',
      });
    },
    onError: () => {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.languageRemovalFailed'),
      });
    },
  });

  // LOCAL State
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [jobOffers, setJobOffers] = useState<JobOffer[]>([]);
  const [terminateWokerDialogOpen, setTerminateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [hashAttributes, setHashAttributes] = useState<HashAttributes>();
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState<boolean>(false);
  const [isLanguageRemovalDialogOpen, setIsLanguageRemovalDialogOpen] = useState<boolean>(false);
  const [applicationId, setSelectedApplicationId] = useState<string>('');
  const [selectedWorker, setSelectedWorker] = useState<WorkerProfile | undefined>();

  // GLOBAL State
  const openConfirmModal = useStoreActions(actions => actions.context.openConfirmDialog);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [getInterviewHash] = useGetInterviewHash({
    onComplete: res => {
      if (res) {
        setHashAttributes(res);
      }
    },
  });

  useEffect(() => {
    getJobOffers();
    getJobApplications({ variables: { jobId } });
    getJobById({ variables: { jobId } });
    getInterviewHash();
    handleGenerationOptions(true, true);
  }, []);

  useEffect(() => {
    if (allJobOffers) {
      const currentJobOffers: JobOffer[] = allJobOffers.filter(
        jobOffer => jobOffer.job.id === jobId,
      );
      setJobOffers(currentJobOffers);
    }
  }, [allJobOffers]);

  if (terminateLoading || loading || !job) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  const isEngOnly = getLanguage(job, 'en', 'hu');

  const handleInviteModalClose = () => {
    getJobOffers();
    setIsInviteModalOpen(false);
  };

  const handleOpenWorkerProfile = (workerData: WorkerProfile) => {
    history.push({
      pathname: `/worker/${workerData.id}`,
      state: { worker: workerData },
    });
  };

  const handleTerminateJob = () => {
    openConfirmModal({
      title: t('jobPage.terminateJobAlertTitle'),
      body: (
        <Box>
          <Trans>jobPage.terminateJobAlertBody</Trans>
        </Box>
      ),
      onConfirm: () => {
        terminateJob({ variables: { id: job.id } });
      },
    });
  };

  const handleLanguageRemoval = () => {
    setIsLanguageRemovalDialogOpen(true);
  };

  const handleConfirmLanguageRemoval = () => {
    setIsLanguageRemovalDialogOpen(false);
    removeLanguage({ variables: { jobId: job.id, selectedLanguages } });
  };

  const handleOpenEditJob = () => {
    setIsEditDialogOpen(true);
  };

  const getEditMode = (): EditModes => {
    const documentReviewOffers = jobOffers.filter(
      offer => offer.status === JOB_OFFER_STATUS.UNDER_REVIEW,
    );
    const notDeclinedOffers = jobOffers.filter(offer => offer.status !== JOB_OFFER_STATUS.DECLINED);

    if (job.workers.length > 0 || documentReviewOffers.length > 0) {
      return EditModes.ActiveJobState;
    }

    if (job.workers.length === 0 && notDeclinedOffers.length > 0) {
      return EditModes.OfferState;
    }

    return EditModes.Full;
  };

  const handleCancelApplication = (applicationId: string) => {
    cancelApplication({ variables: { applicationId } });
  };

  const handleAcceptApplication = (applicationId: string) => {
    acceptApplication({ variables: { applicationId } });
  };

  const openApplicationModal = (worker: WorkerProfile, applicationId: string) => {
    setSelectedWorker(worker);
    setSelectedApplicationId(applicationId);
    setIsApplicationModalOpen(true);
  };

  const handleGeneration = () => {
    if (!job) return;

    setTextLoading(shouldGenerateText);
    setImageLoading(shouldGenerateImage);

    generateFbPost({ variables: { jobId: job.id, shouldGenerateText, shouldGenerateImage } });
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
              {!isEngOnly ? job.role.name : job.role.localizedData?.en?.name}
            </Typography>
            <Box display="flex" mt={2}>
              <Box mt={1} mr={1}>
                <Button
                  sx={{ width: '9rem', height: 45, fontSize: 12 }}
                  variant={BUTTON_VARIANT.CONTAINED}
                  color={BUTTON_COLOR.ERROR}
                  type="submit"
                  disabled={job.status === JOB_STATUS.TERMINATED}
                  onClick={handleTerminateJob}>
                  <Trans>jobPage.terminateJob</Trans>
                </Button>
              </Box>
              {enJob && huJob && (
                <Box mt={1} mr={1}>
                  <Button
                    sx={{ width: '9rem', height: 45, fontSize: 12 }}
                    variant={BUTTON_VARIANT.GRADIENT}
                    color={BUTTON_COLOR.INFO}
                    type="submit"
                    onClick={handleLanguageRemoval}>
                    <Trans>jobPage.removeLanguage</Trans>
                  </Button>
                </Box>
              )}
              <Box mt={1} mr={1}>
                <Button
                  sx={{ width: '9rem', height: 45, fontSize: 12 }}
                  variant={BUTTON_VARIANT.GRADIENT}
                  color={BUTTON_COLOR.INFO}
                  type="submit"
                  disabled={getEditMode() === EditModes.ActiveJobState}
                  onClick={handleOpenEditJob}>
                  <Trans>jobPage.editJob</Trans>
                </Button>
              </Box>
              <Box mt={1} mr={1}>
                <Button
                  sx={{ width: '9rem', height: 45, fontSize: 12 }}
                  variant={BUTTON_VARIANT.GRADIENT}
                  color={BUTTON_COLOR.INFO}
                  type="submit"
                  onClick={handleGeneration}>
                  {textGenerationLoading ? (
                    <CircularProgress size={12} color="inherit" />
                  ) : (
                    <Trans>jobPage.generateTextAndImage</Trans>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {job && job.status === JOB_STATUS.TERMINATED && (
          <Box mt={2} mb={2}>
            <Typography
              variant="h3"
              fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
              color={TYPOGRAPHY_COLORS.ERROR}>
              <Trans>jobPage.terminatedJobInfo</Trans>
            </Typography>
          </Box>
        )}
        <WorkForceViewContent
          jobOffers={jobOffers}
          jobApplications={jobApplications}
          job={job}
          openApplicationModal={openApplicationModal}
          setTerminateDialogOpen={setTerminateDialogOpen}
          setIsInviteModalOpen={setIsInviteModalOpen}
          handleOpenWorkerProfile={handleOpenWorkerProfile}
        />
      </Grid>
      <CreateJobOfferModal
        isOpen={isInviteModalOpen}
        handleClose={handleInviteModalClose}
        jobId={jobId}
        jobStatus={job.status}
      />
      <RemoveWorkersFromJobDialog
        jobId={job.id}
        isOpen={terminateWokerDialogOpen}
        selectableWorkers={[...job.workers, ...jobOffers.map(offer => offer.worker)]}
        handleCloseDialog={() => {
          setTerminateDialogOpen(false);
        }}
      />
      <CreateJobDialog
        open={isEditDialogOpen}
        setOpen={setIsEditDialogOpen}
        editRoleData={job.role}
        editJobData={job}
        editMode={getEditMode()}
        onSuccessCallback={newJob => getJobById({ variables: { jobId: newJob.id } })}
      />
      {hashAttributes && selectedWorker && (
        <ApplicationModal
          isOpen={isApplicationModalOpen}
          handleClose={() => setIsApplicationModalOpen(false)}
          worker={selectedWorker}
          hashAttributes={hashAttributes}
          loading={acceptApplicationLoading || cancelApplicationLoading}
          handleAcceptApplication={handleAcceptApplication}
          handleCancelApplication={handleCancelApplication}
          applicationId={applicationId}
          handleOpenWorkerProfile={handleOpenWorkerProfile}
        />
      )}
      <ModalChatGPT
        shouldOpen={showModal}
        textContents={modalText}
        imageContents={modalImage}
        handleClose={handleCloseModal}
        textGenerationLoading={textGenerationLoading}
        imageGenerationLoading={imageGenerationLoading}
        handleGeneration={() => handleGeneration()}
      />
      <Dialog
        open={isLanguageRemovalDialogOpen}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={() => setIsLanguageRemovalDialogOpen(false)}>
        <DialogTitle>{t('jobPage.removeLanguageAlertTitle')}</DialogTitle>
        <DialogContent sx={{ height: 170 }}>
          <Box mb={2}>
            <Trans>jobPage.removeLanguageAlertBody</Trans>
          </Box>
          <LanguageRemoval
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLanguageRemovalDialogOpen(false)} size={BUTTON_SIZE.SMALL}>
            <Trans>generic.cancel</Trans>
          </Button>
          <Button
            disabled={!(selectedLanguages.en || selectedLanguages.hu)}
            onClick={handleConfirmLanguageRemoval}
            variant={BUTTON_VARIANT.GRADIENT}
            size={BUTTON_SIZE.SMALL}
            color={BUTTON_COLOR.INFO}>
            <Trans>generic.save</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Box mt={1} mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                <Trans>jobPage.jobDetails.title</Trans>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Card>
              <CardContent>
                <JobDetails job={job} />
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
