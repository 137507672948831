import React from 'react';

import { Box } from 'src/components/Box';
import borders from 'src/theme/base/borders';
import colors from 'src/theme/base/colors';
import typography from 'src/theme/base/typography';

import { DataTableBodyCellProps } from './types';

function DataTableBodyCell({ noBorder = false, align = 'left', children }: DataTableBodyCellProps) {
  const { light } = colors;
  const { size } = typography;
  const { borderWidth } = borders;

  return (
    <Box
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      py={2}
      px={3}>
      <Box
        display="inline-block"
        maxWidth="max-content"
        color="text"
        sx={{ verticalAlign: 'middle' }}>
        {children}
      </Box>
    </Box>
  );
}

export default DataTableBodyCell;
