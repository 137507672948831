/* eslint-disable indent */
import React, { useState } from 'react';
import { Grid, Tabs, Tab, Card, CardContent, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { JobDetailData } from 'src/pages/Job/components/JobDetailData';
import colors from 'src/theme/base/colors';
import rgba from 'src/theme/functions/rgba';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { FinancialDetails } from 'src/components/FinancialDetails';
import { JobInfoSection } from 'src/components/JobInfoSection';
import { JobDetailsProps } from './types';

export const JobDetails: React.FC<JobDetailsProps> = ({ job }) => {
  const { t } = useTranslation();
  const { info } = colors;

  const [tabValue, setTabValue] = useState<number>(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const isEnglishTabSelected = tabValue === 1;

  return (
    <Box>
      {job?.role?.localizedData?.en?.selectedLanguages?.hu &&
        job?.role?.localizedData?.en?.selectedLanguages?.en && (
          <Box display="flex" justifyContent="left" alignItems="center" mb={2}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                backgroundColor: 'transparent',
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiTabs-indicator': {
                  backgroundColor: rgba(info.main, 1),
                  height: '5px',
                  opacity: 0.8,
                },
              }}>
              <Tab
                label={t('jobPage.jobDetails.hungarianTab')}
                sx={{ padding: 1, fontWeight: TYPOGRAPHY_WEIGHT.BOLD }}
              />
              <Tab
                label={t('jobPage.jobDetails.englishTab')}
                sx={{ padding: 1, fontWeight: TYPOGRAPHY_WEIGHT.BOLD }}
              />
            </Tabs>
          </Box>
        )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <JobInfoSection
                label={t('jobPage.createRoleForm.jobDescription')}
                content={
                  isEnglishTabSelected
                    ? job?.role?.localizedData?.en?.description ||
                      t('jobPage.createJobModal.notProvided')
                    : job?.role?.description || t('jobPage.createJobModal.notProvided')
                }
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <JobInfoSection
                label={`${t('jobPage.financialDetails')}:`}
                content={
                  <FinancialDetails
                    hourlyGrossWage={job.wage.hourlyGrossAmount}
                    monthlyHours={job.monthlyHours}
                  />
                }
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <JobInfoSection
                    label={`${t('jobPage.createJobModal.data')}:`}
                    content={<JobDetailData job={job} englishJob={isEnglishTabSelected} />}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
