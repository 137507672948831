import { useTranslation } from 'react-i18next';
import {
  useTerminateWorker,
  useCancelJobApplication,
  useAcceptJobApplication,
} from 'src/api/hooks/useJob';
import { useRemoveWorkerRequest, useResendInviteRequest } from 'src/api/hooks/useWorker';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';

export const useWorkerActions = () => {
  const { t } = useTranslation();
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [resendWorkerInvite, { loading: resendWorkerInviteLoading }] = useResendInviteRequest({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.resendInvite.error'),
      }),
  });

  const [removeWorkerFromCompany, { loading: removeWorkerFromCompanyLoading }] =
    useRemoveWorkerRequest({
      onComplete: () => window.location.reload(),
      onError: () =>
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.error,
          message: t('workersPage.actions.removeWorkerFromCompany.error'),
        }),
    });

  const [terminateWorker, { loading: terminateWorkersLoading }] = useTerminateWorker({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.terminateWorker.error'),
      }),
  });

  const [declineApplication, { loading: cancelApplicationLoading }] = useCancelJobApplication({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.declineApplication.error'),
      }),
  });

  const [acceptApplication, { loading: acceptApplicationLoading }] = useAcceptJobApplication({
    onComplete: () => window.location.reload(),
    onError: () =>
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersPage.actions.acceptApplication.error'),
      }),
  });

  const isLoading =
    acceptApplicationLoading ||
    cancelApplicationLoading ||
    terminateWorkersLoading ||
    removeWorkerFromCompanyLoading ||
    resendWorkerInviteLoading;

  return {
    isLoading,
    resendWorkerInvite,
    acceptApplication,
    declineApplication,
    terminateWorker,
    removeWorkerFromCompany,
  };
};
