import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Icon,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';

import { Input } from '../Input';
import { ColumnType, DropDownFilterProps } from './types';
import { Trans, useTranslation } from 'react-i18next';
import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { Typography } from '../Typography';
import { Box } from '../Box';
import typography from 'src/theme/base/typography';
import colors from 'src/theme/base/colors';
import { useLabels } from 'src/hooks/useLabels';
import { getStatusLabel } from 'src/utils/getStatusLabel';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import theme from 'src/theme';

export const DropDownFilter: React.FC<DropDownFilterProps & { columnType: ColumnType }> = ({
  column: { filterValue, setFilter, id, preFilteredRows },
  columnType,
}) => {
  const { statusLabels } = useLabels();

  const isScreenBelowXXL = useMediaQuery(theme.breakpoints.down('xxl'));

  const options = Array.from(
    new Set(preFilteredRows.map(row => row.values[id]).filter(item => item !== undefined)),
  ) as string[];

  const columnStatus = columnType === ColumnType.STATUS;
  const columnAction = columnType === ColumnType.ACTION;

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFilter(e.target.value);
  };

  const handleStatusLabel = (value: string): string => {
    return columnStatus ? getStatusLabel(value as POOL_STATUS, statusLabels) : value;
  };

  const renderValue = (value: string) => {
    if (!value) {
      return (
        <Box height={isScreenBelowXXL ? theme.spacing(8) : theme.spacing(0.5)}>
          <Typography
            sx={{
              fontSize: typography.size.sm,
              color: colors.grey[500],
              whiteSpace: 'normal',
              lineHeight: isScreenBelowXXL ? 1.5 : 0.5,
            }}
            fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
            <Trans>
              {!columnAction
                ? columnStatus
                  ? 'workersPage.filterByStatus'
                  : 'workersPage.filterByJob'
                : 'workersPage.includeAll'}
            </Trans>
          </Typography>
        </Box>
      );
    }
    return (
      <Box height={isScreenBelowXXL ? theme.spacing(8) : theme.spacing(0.5)}>
        <Typography
          sx={{
            fontSize: typography.size.sm,
            color: colors.grey[500],
            whiteSpace: 'normal',
            lineHeight: isScreenBelowXXL ? 1.5 : 0.5,
          }}>
          {handleStatusLabel(value)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{
          width: {
            xs: theme.spacing(14),
            xl: theme.spacing(18),
            xxl: theme.spacing(28),
          },
        }}>
        <Select
          disabled={columnAction ? true : false}
          displayEmpty
          value={filterValue || ''}
          onChange={handleChange}
          renderValue={renderValue}
          input={
            <Input
              icon={{
                component: <Icon>expand_more</Icon>,
                direction: 'right',
              }}
            />
          }
          sx={{
            height: theme.spacing(8),
            '& .MuiSelect-select': {
              height: '100%',
            },
          }}>
          <MenuItem value="">
            <Trans>workersPage.includeAll</Trans>
          </MenuItem>
          {options.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {handleStatusLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const SearchFilter: React.FC<DropDownFilterProps & { columnType: ColumnType }> = ({
  column: { filterValue, setFilter },
  columnType,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1400));

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedOption = e.currentTarget.value;
    setFilter(selectedOption || undefined);
  };

  const isColumnPhoneNumber = columnType === ColumnType.PHONE_NUMBER;

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{
          width: {
            xs: theme.spacing(14),
            xl: theme.spacing(18),
            xxl: theme.spacing(28),
          },
        }}>
        <Input
          rows={isSmallScreen ? 3 : 1}
          multiline={isSmallScreen ? true : false}
          type={isColumnPhoneNumber ? 'number' : 'text'}
          placeholder={
            isColumnPhoneNumber
              ? t('workersPage.filterByPhoneNumber')
              : t('workersPage.filterByName')
          }
          value={filterValue || ''}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
};
