import React from 'react';
import { Button } from 'src/components/Button';
import { WorkerActionsButtonProps } from './types';
import { Typography } from '@mui/material';
import { TYPOGRAPHY_WEIGHT, TYPOGRAPHY_COLORS } from '../Typography/types';
import theme from 'src/theme';

export const WorkerActionsButton: React.FC<WorkerActionsButtonProps> = ({
  label,
  variant,
  color,
  onClick,
  disabled = false,
}) => (
  <Button
    sx={{
      width: {
        xs: theme.spacing(14),
        xl: theme.spacing(18),
        xxl: theme.spacing(28),
      },
    }}
    variant={variant}
    color={color}
    onClick={onClick}
    disabled={disabled}>
    <Typography
      variant="button"
      sx={{ fontSize: { xxl: 12, xl: 10, lg: 9, xs: 9 } }}
      fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
      color={TYPOGRAPHY_COLORS.WHITE}>
      {label}
    </Typography>
  </Button>
);
