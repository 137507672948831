import React from 'react';

// @mui material components
import Icon from '@mui/material/Icon';

import { Box } from 'src/components/Box';

import { DataTableHeadCellProps } from './types';
import colors from 'src/theme/base/colors';
import borders from 'src/theme/base/borders';
import theme from 'src/theme';

function DataTableHeadCell({
  width = 'auto',
  children,
  sorted = 'none',
  align = 'left',
  ...rest
}: DataTableHeadCellProps) {
  const { light } = colors;
  const { borderWidth } = borders;

  return (
    <Box
      component="th"
      width={width as string}
      borderBottom={`${borderWidth[1]} solid ${light.main}`}
      py={2}
      px={3}>
      <Box
        {...rest}
        position="relative"
        textAlign={align}
        color="secondary"
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }: any) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: 'uppercase',
          cursor: sorted && 'pointer',
          userSelect: sorted && 'none',
          width: {
            xs: theme.spacing(14),
            xl: theme.spacing(18),
            xxl: theme.spacing(28),
          },
        })}>
        {children}
        {sorted && (
          <Box
            position="absolute"
            top={0}
            right={align !== 'right' ? '16px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
            sx={({ typography: { size } }: any) => ({
              fontSize: size.lg,
            })}>
            <Box
              position="absolute"
              top={-6}
              color={sorted === 'asce' ? 'text' : 'secondary'}
              opacity={sorted === 'asce' ? 1 : 0.5}>
              <Icon>arrow_drop_up</Icon>
            </Box>
            <Box
              position="absolute"
              top={0}
              color={sorted === 'desc' ? 'text' : 'secondary'}
              opacity={sorted === 'desc' ? 1 : 0.5}>
              <Icon>arrow_drop_down</Icon>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DataTableHeadCell;
