export default {
  generic: {
    welcome: 'Üdv',
    login: 'Belépés',
    support: 'Támogatás',
    logout: 'Kilépés',
    viewAll: 'Összes',
    loading: 'Folyamatban...',
    viewAllJobs: 'Összes állás',
    openFacebook: 'Tovább a Della Facebook oldalára',
    openMessenger: 'Írj nekünk Facebookon',
    openEmail: 'Írj nekünk',
    accept: 'Elfogadás',
    postCode: 'Irányítószám',
    city: 'Város',
    street: 'Utca',
    streetNumber: 'Házszám',
    cancel: 'Mégsem',
    save: 'Jóváhagyás',
    OK: 'OK',
    hour: 'óra',
    add: 'Új hozzáadása',
    location: 'Helyszín',
    previous: 'Vissza',
    hourly: 'Óránként',
    monthly: 'Havonta',
    noData: 'Jelenleg nincs adat',
    yes: 'Igen',
    no: 'Nem',
    backToLogin: 'Vissza a bejelentkezéshez',
    backToHome: 'Vissza a főoldalra',
    net: 'Netto',
    back: 'Vissza',
    register: 'Regisztráció',
    male: 'Férfi',
    female: 'Nő',
    decline: 'Elutasít',
    loginUpsell: 'Fiatal, rugalmas és dinamikus munkaerő néhány kattintással',
    month: 'hó',
    sign: 'Aláírom',
    studetnOrg: 'HelloStudent Iskolaszövetkezet',
    noti: 'Értesítés',
    look: 'Megtekint',
    notFound: 'Az oldal nem található',
  },
  menu: {
    home: 'Főoldal',
    jobs: 'Munkák',
    notifications: 'Értesítések',
    profile: 'Profil',
    support: 'Ügyfélszolgálat',
    management: 'Ügyintézés',
  },

  jobPage: {
    data: 'Részletek',
    workForce: 'Munkaerő',
    createJobTitle: 'Munka létrehozása',
    activeJobs: 'Aktív munkák',
    inactiveJobs: 'Inaktív munkák',
    title: 'Nézd meg jelenleg is aktívan dolgozó diákjainkat',
    activeWorkers: 'Aktív diákok',
    applicants: 'Jelentkezők',
    invited: 'Meghívottak',
    cta: 'Ha te is szeretnél hasonló munkavállalókat akik megfelelnek az igényeidnek, hozz létre egy állást!',
    jobDetailPageTitle: 'Munka részletei:',
    createRole: 'Munkakör létrehozása',
    createRoleHelper: 'Új munka létrehozásához elöször adjon hozzá role-t',
    createJob1: 'Új állás',
    createJob: 'Új állás',
    hungarianLanguage: 'Magyar nyelv',
    englishLanguage: 'Angol nyelv',
    noJobsYet: '(A lista jelenleg üres, hozz létre egy új állást.)',
    hoursPerMonth: 'Óra/hét',
    worker: 'Diák',
    workers: 'Diákok',
    expectedWorkingHours: 'Várható óraszám',
    financialDetails: 'Órabérek',
    terminateJob: 'Munka lezárása',
    languageHasBeenRemoved: 'A kiválasztott nyelv sikeresen eltávolításra került',
    languageRemovalFailed: 'A kiválasztott nyelv eltávolítása nem sikerült',
    removeLanguage: 'Nyelv törlése',
    removeLanguageAlertTitle: 'Szeretnéd törölni az egyik nyelvet?',
    removeLanguageAlertBody:
      'A munka törlésre kerül a kiválasztott nyelven, és csak a fennmaradó nyelven elérhető verzió marad meg.',
    editJob: 'Munka szerkesztése',
    generateTextAndImage: 'Kép és szöveg generálás',
    workerInvitationRollback: 'Diákok lemondása',
    terminatedJobInfo: 'Az állás már le lett zárva, nem módosítható',
    jobHasBeenTerminated: 'Az állásajánlat sikeresen lezárásra került',
    jobTerminationFailed: 'Az állásajánlat lezárása nem sikerült',
    terminateJobAlertTitle: 'Le szeretnéd zárni a munkát?',
    terminateJobAlertBody:
      'Az összes jelentkezés vissza lesz vonva, továbbá elkészülnek az elszámolások.',
    jobDetails: {
      title: 'Munka részletei',
      englishTab: 'Angol nyelv',
      hungarianTab: 'Magyar nyelv',
    },
    removeWorkersFromJobDialog: {
      title: 'Állás felmondása Diák részére',
      helperText: 'Itt vonhatsz vissza állásra való jelentkezéseket',
      selectedWorkers: 'Kiválasztottak száma: {{number}}',
      quitWorkerFromJob: '{{number}} diák visszavonása',
      teminateWorkerFailed: 'Sikertelen művelet. Nem sikerült a diákok állásának visszavonása',
      cancelJobApplicationFailed:
        'Sikertelen művelet. Nem sikerült a diák jelentkezésének lemondása',
      cancelJobApplicationSuccess: 'A diák jelentkezése sikeresen el lett utasítva',
      acceptJobApplicationFailed:
        'Sikertelen művelet. Nem sikerült a diák jelentkezésének elfogadása',
      acceptJobApplicationSuccess: 'A diák jelentkezése sikeresen el lett fogadva',
    },
    createJobForm: {
      hourlyWage: 'Óránkénti költség',
      hourlyNetAmount: 'Nettó órabér: {{amount}}',
      hourlyComission: 'Iskolaszövetkezeti költség: {{amount}}',
      hourlyGrossWage: 'Bruttó órabér: {{amount}}',
      hourlyPersonalTax: 'Személyi jövedelemadó: {{amount}}',
      hourlyAmountToReceive: 'Diák nettó órabére: {{amount}}',
      hourlyAmountYouPay: 'Diák költsége óránként: ',
      totalNetAmount: 'Havi nettó bér: {{amount}}',
      totalComission: 'Havi iskolaszövetkezeti költség: {{amount}}',
      totalGrossWage: 'Havi bruttó bér: {{amount}}',
      totalPersonalTax: 'Havi SZJA: {{amount}}',
      totalAmountToReceive: 'Diák által megkapott havi bér: {{amount}}',
      selectLanguage: 'Válassz nyelvet',
      selectRole: 'Válassz munkakört',
      buttonLabel: 'Munka létrehozása',
      netWage: 'Nettó bér',
      personalTax: 'SZJA',
      grossWage: 'Bruttó bér',
      comission: 'Iskolaszövetkezet költsége',
      amountYouPay: 'Vállalkozás által fizetendö összeg',
      confirm: 'Megerősités',
      inviteTitle: 'Hívj meg diákokat a munkára',
      selectedWorkers: 'Kiválasztottak  száma: ',
      confirmTitle: 'Adatok megerősítése',
      success: 'Munka sikeresen létre lett hozva',
      inviteAfterActivation: 'Aktiválás után az állásajánlatok el lesznek küldve.',
      error: 'Munka létrehozása sikertelen',
      confirmCloseTitle: 'Biztos vagy benne hogy ki szeretnél lépni?',
      confirmCLoseText: 'Az adatok nem fognak mentésre kerülni',
      editButtonLabel: 'Munka szerkesztése',
      editSuccess: 'A munka sikeresen frissītve lett.',
      editError: 'Hiba történt a munka frissītése során',
      longTermJob: 'Folyamatos munka',
      shortTermJob: 'Alkalmi munka',
      timeSchedule: 'Mennyi időt kell dolgozni? Melyik napokon? Mikor?',
      timeScheduleEn: 'Mennyi időt kell dolgozni? Melyik napokon? Mikor? (angolul)',
      workerCount: 'Hány diákra lesz szükség összesen? (Később módosítható)',
      startNow: 'Azonnal kezdhető',
      girlsAndBoys: 'fiúk és lányok jelentkezését egyaránt várjuk.',
      girls: 'lányok jelentkezését várjuk.',
      boys: 'fiúk jelentkezését várjuk.',
      language: 'Nyelvtudás',
      hungarianSkills: 'Magyar nyelvtudás szükséges a munka elvégzéséhez',
      drivingLicense: 'Jogosítvány szükséges a munka elvégzéséhez?',
      documents: 'A munkavégzéshez szükséges dokumentumok',
      documentsEn: 'A munkavégzéshez szükséges dokumentumok (angolul)',
      documentsPlaceholder: 'pl. Egeszségügyi kiskönyv, tüdőszűrő',
      extraSkills: 'Szükséges készségek, tapasztalat és egyéb elvárások',
      extraSkillsEn: 'Szükséges készségek, tapasztalat és egyéb elvárások (angolul)',
      noLicense: 'Nem szükséges',
      car: 'Autó',
      motorCycle: 'Motor',
      englishSkills: 'Angol nyelvtudás szükséges a munka elvégzéséhez',
      bonus: 'Extrák a diákoknak:',
      bonusEn: 'Extrák a diákoknak (angolul):',
      bonusPlaceholder:
        'pl. Ingyenes buszjárat a munkavégzés helyszínére, ingyenes étkezés, ingyenes szállás',
      contactPerson: 'Kontakt személy',
      contactPhone: 'Kontakt telefonszám',
      contactEmail: 'Kontakt email cím',
      equipmentInfo: 'Hogyan érkezzen a diák?',
      equipmentInfoEn: 'Hogyan érkezzen a diák? (angolul)',
      equimentInfoPlaceHolder:
        'pl. Zárt cipő viselete kötelező. Munkakezdés előtt 15 perccel kérjük a megjelenést.',
      arriveInfo: 'Hol, kit keressen a munkavégzés helyszínén?',
      arriveInfoEn: 'Hol, kit keressen a munkavégzés helyszínén? (angolul)',
      arrivePlaceholder:
        'pl. A főbejárattól jobbra lévő recepción kell bejelentkezni, utána a 106-os irodában Kiss Béla ad eligazítást.',
      under25: '25 év alatti diák esetén',
      over25: '25 év feletti diák esetén',
      breakAlert:
        '* Amennyiben a feladatteljesítés tartama a napi hat órát meghaladja, napi húsz, ha a napi kilenc órát meghaladja, további napi huszonöt perc munkaközi szünetet kell biztosítani. Az iskolaszövetkezet nappali tagozatos tanuló részére, ha két egymást követő napon végzi feladatát, a napi munka befejezése és a következő napi munka megkezdése között legalább tizenegy óra napi pihenőidőt kell biztosítani.',
      breakAlertTitle: 'A munkatörvény által előírt kötelező szünet a diákok számára.',
      isInterviewNeeded: 'Készítsünk a diákokkal videóinterjút? (nincs extra költsége)',
      incorrectWage: 'Helytelen bruttó bér',
    },
    createJobModal: {
      title: 'Létrehozod az alábbi munka lehetőséget?',
      jobTitle: 'Munka neve: {{jobTitle}}',
      jobTitleEn: 'Munka neve (angolul): {{jobTitleEn}}',
      jobDescription: 'Munka leírása: {{jobDescription}}',
      jobDescriptionEn: 'Munka leírása (angolul): {{jobDescriptionEn}}',
      jobLocation: 'Munka helyszíne: {{jobLocation}}',
      hourlyWage: 'Munka óradíja: {{hourlyWage}}',
      chooseOrCreate: 'Válasszon munkakört vagy adjon hozzá újat',
      expectedWorkingHours: 'Heti Várható Óraszám',
      create: 'Új munkakör létrehozása',
      helperText1: 'Kérjük add meg átlagosan hány órát fog dolgozni a diák',
      helperText2:
        'Az alábbi adatok a heti óraszámból és óradíjból kalkulált tájékoztató jellegű adatok havi 4 ledolgozott munkahét esetén.',
      helperText3:
        'Az alábbi listában látod a diákokat, akivel már dolgoztál. Amennyiben új diákot szeretnél meghívni, kérjük kattints az új hozzáadása gombra.',
      helperText4: 'Min. 1 és max. 48 óra adható meg hetente',
      contactPerson: 'Kit keressen a diák?',
      instructions: 'Instrukciók az első munkanaphoz',
      recruitmentNeeded:
        'A meghirdetett állásra az iskolaszövetkezet megkezdi a toborzást, a munkát meghirdeti, valamint elérhetővé teszi a Della alkalmazás felhasználói számára. A pozícióra a diákok jelentkezést nyújthatnak be.',
      hiddenPosition:
        'Az állásra az iskolaszövetkezet nem toboroz, az a Della alkalmazásban rejtve marad azon diákok számára, akiket a cég erre nem hívott meg.',
      recruitmentNeededLabel: 'Toborzást kérek',
      hiddenPositionLabel: 'Rejtett pozíció',
      data: 'Adatok',
      jobType: 'Munka típusa',
      schedule: 'Időbeosztás',
      scheduleEn: 'Időbeosztás (angolul)',
      workerCount: 'Szükséges létszám',
      hungarian: 'Magyar',
      english: 'Angol',
      recruitmentType: 'Szükséges-e toborzás?',
      notProvided: '<Nincs megadva>',
    },
    createRoleForm: {
      jobTitle: 'Munkakör megnevezése',
      jobTitleEn: 'Munkakör megnevezése (angolul)',
      jobDescription: 'Mi a pontos teendő ebben a munkakörben?',
      jobDescriptionEn: 'Mi a pontos teendő ebben a munkakörben? (angolul)',
      submitButtonLabel: 'Tovább',
      companyProfile: 'Mivel foglalkozik a cég?',
      companyProfileEn: 'Mivel foglalkozik a cég? (angolul)',
    },
    createJobOfferModal: {
      title: 'Válaszd ki a diákot, akit meg akarsz hívni',
      workerSelectTitle: 'Válassz diákot',
      jobSelectTitle: 'Válassz munkát',
      invite: 'Meghívás',
      noWorker: 'Nincs még meghívott diákod',
      noJob: 'Nem vettél még fel munkát',
      createJobOffer: {
        success: 'Állásajánlat elküldve',
        inactiveJobSuccess: 'Állásajánlat el lesz küldve a munka aktiválása után',
        error: 'Nem sikerült elküldeni az állásajánlatot',
      },
    },
    editJobModal: {
      cancelTitle: 'Ez a munka nem szerkeszthető',
      cancelDescription:
        'There are valid contracts associated with this role/job, you cannot edit it, alternatively you can cancel this role, create a new one and reinvite your workers',
    },
    GenerationModal: {
      title: 'AI segítségével a munkád alapján generáltunk egy posztjavaslatot!',
      reGeneration: 'Újragenerálás',
      copy: 'Másolás',
      copied: 'Másolva',
      error: 'Nem sikerült a szöveg másolása',
      version: 'Verzió ',
      cancel: 'Bezárás',
      text: {
        generating: 'Szöveg generálása, kérlek várj..',
      },
      image: {
        generating: 'Kép generálása, kérlek várj..',
        empty: 'Kép nem található',
        higherResolution: 'Kattins a nagyobb felbontásért!',
      },
      generationSuccess: 'A Facebook poszt generálása sikeresen megtörtént',
      generationError: 'Hiba történt a Facebook poszt generálása közben',
    },
  },
  alerts: {
    phoneNumberValidate: {
      error: 'Helytelen a megadott telefonszám',
    },
    smsSending: {
      error: 'Sms küldés sikertelen. Online vagy?',
    },
    verificationCode: {
      error: 'Hibás email vagy jelszó, kérjük próbáld újra!',
    },
    passwordReset: {
      title: 'Jelszóemlékeztető',
      askPasswordReset: 'Küldés',
      passwordResetBody: 'Elfelejtetted a jelszavad? Add meg az e-mail címed, és küldünk egy újat.',
      placeholder: 'Email cím',
      error: 'Jelszóemlékeztető email küldése sikertelen',
      success: 'Jelszóemlékeztető email küldése sikeres',
    },
    registration: {
      error: 'Regisztráció sikertelen, kérlek próbáld újra!',
    },
    getJobOffer: {
      error: 'Hiba történt az állás ajánlatok letöltése közben',
    },
    acceptJobOffer: {
      error: 'Hiba történt az állásajánlat elfogadása közben',
      success: 'Sikeresen elfogadta az állásajánlatot',
    },
    declineJobOffer: {
      error: 'Hiba történt az állásajánlat elutasítása közben',
      success: 'Sikeresen elutasította az állásajánlatot',
    },
    calendar: {
      error: 'Hiba történt a naptárbejegyzés létrehozása közben',
      success: 'Sikeresen hozzáadtuk az eseményeket a naptárához',
    },
    map: {
      error: 'Nem tudtuk lekérdezni a munka helyszínének adatait.',
    },
    applyJob: {
      error: 'Hiba történt a jelentkezés közben. Kérlek próbáld újra',
      success: 'Sikeresen jelentkeztél az állásra',
    },
    cancelJobApplication: {
      error: 'Hiba történt a jelentkezés visszavonása közben. Kérlek próbáld újra',
      success: 'Sikeresen visszavontad a jelentkezésedet.',
    },
    getActiveJobs: {
      error: 'Hiba történt az aktuális állásaid lekérése közben',
    },
    getPendingJobApplications: {
      error: 'Hiba történt az állásjelentkezéseid lekérése közben',
    },
    getJobConfirmations: {
      error: 'Hiba történt az elszámolások lekérése közben',
    },
    acceptJobConfirmations: {
      error: 'Hiba történt az elszámolások lekérése közben',
      success: 'Sikeresen igazoltál {{hoursWorked}} órát',
    },
    getAccount: {
      error: 'Hiba történt a pénzügyi adatok lekérése közben',
    },
    signContract: {
      error: 'Hiba történt a szerződés aláīrása közben. Kérjük próbálja később.',
      success: 'A szerződés sikeresen alá lett írva, hamarosan megküldjük önnek emailben.',
    },
  },
  alertPopup: {
    notSufficientData: {
      title: 'Nem megfelelő adatok',
      helperText: 'Kérlek töltsd fel a diákigazolványod',
      accept: 'Feltöltés most',
      decline: 'Később',
    },
    cancelApplication: {
      title: 'Biztosan visszavonod a jelentkezésedet?',
      helperText: 'Ha később meggondolod magad, újra jelentkezned kell!',
      accept: 'Visszavonás',
      decline: 'Bezárás',
    },
    notANumber: 'Számnak kell lennie',
  },
  validation: {
    required: 'Kötelező',
    minLength: 'Legalább {{number}} karakter hosszúnak kell lennie',
    invalidEmail: 'Helytelen email cím',
    invalidPhone: 'Helytelen telefonszám',
    minAndMaxlength: 'Legalább {{min}} és legfeljebb {{max}} karakter hosszúnak kell lennie',
    minimum6Char: 'Minimum 6 karakter',
    minimum4Char: 'Minimum 4 karakter',
    minimum3Char: 'Minimum 3 karakter',
    minimum11Char: 'Minimum 11 karakter',
    minimum10Char: 'Minimum 10 karakter',
    max4Char: 'Maximum 4 karakter',
    onlyNumbers: 'Csak számokat tartalmazhat',
    onlyNumbersVAT:
      'Az adószámnak legalább 11, legfeljebb 13 karakterből kell állnia, az engedélyezett formátumoknak megfelelően: 12345678-9-12 vagy 12345678912.',
    passwordMatch: 'Jelszavak nem egyeznek',
    passwordValidation:
      'A jelszónak min. 6 max. 20 karakterből kell állnia. Tartalmaznia kell számot, és minimum egy nagy - és kis betűt.',
  },
  loginScreen: {
    title: 'Kérlek add meg a telefonszámod',
    email: 'Email',
    password: 'Jelszó',
    supportText:
      'Della Manager - segítségért lépj velünk kapcsolatba: peter.pal@della.hu | +36 20 421 8158',
    phoneNumberCountryPrefix: 'Ország előtag',
    helperText: 'Kérlek add meg a {{phoneNumber}} számra küldött kódot',
    notReceivedText: 'Nem kaptad meg a kódot? - Kattints ide az újrakezdéshez',
    askPasswordReset: 'Jelszóemlékeztető küldés',
    linkToRegister: 'Nincs még felhasználód?',
    signUp: 'Regisztrálj!',
    alreadyHaveAccount: 'Van már felhasználód?',
  },
  homeScreen: {
    tabs: {
      financeTabTitle: 'Pénzügy',
      confirmationTabTitle: 'Igazolások',
      workersTabTitle: 'Diákok',
    },
  },
  registrationScreen: {
    mainText: 'Szeretnéd használni a Dellát?',
    helperText:
      'Kérjük add meg vállalkozásod adataidat a regsiztrációhoz. *(A cím megadásánal kérlek a Cég székhelyét add meg.)',
    nameOfTheBusiness: 'Céged teljes neve',
    zip: 'Irányítószám',
    city: 'Település neve',
    phoneNumber: 'Telefonszámod',
    address: 'Közterület, Házszám',
    country: 'Ország',
    password: 'Jelszó',
    email: 'Regisztrációs e-mail cím',
    vatNumber: 'Adószám',
    registrationNumber: 'Cégjegyzékszám',
    organizationType: 'Szervezettípus',
    organizationCompany: 'Cég',
    organizationStudentOrg: 'Iskolaszövetkezet',
    representative: 'Cég képvisetére jogosult személy',
    button: 'Regisztráció',
    confirmDetails: 'Adatok megerősítése',
    confirmHelperText:
      'Kérjük ellenőrizd, hogy a megadott információk helyesek-e. Kattints a szerkesztés gombra a módosításhoz.',
    confirm: 'Megerősítés',
    edit: 'Szerkesztés',
    confirmPassword: 'Jelszó megerősítése',
    registerStudentOrg: {
      label1: 'Iskolaszövetkezetként szeretnék regisztrálni',
    },
    readAndAcceptTOS: {
      label1: 'Elolvastam és megértettem a <a>felhasználási feltételeket</a>',
      error: {
        required: 'El kell fogadnod a felhasználási feltételeket',
      },
    },
    readAndAcceptPrivacy: {
      label1:
        'A kapcsolódó <a>adatkezelési tájékoztatókat</a> elolvastam, és elfogadom a megadott adatoknak a tájékoztatókban foglaltak szerinti kezelését, tárolását',
      error: {
        required: 'El kell fogadnod a adatkezelési irányelveket',
      },
    },
  },
  workersScreen: {
    worker: 'Munkavállaló',
    phone: 'Telefon',
    status: 'Státusz',
    job: 'Munkák',
    actions: 'Műveletek',
    inviteWorkers: 'Diákok meghívása',
    inviteWorkersForm: {
      phoneNumber: 'Telefonszám',
      name: 'Név',
      invite: 'Meghívás',
      cancel: 'Mégsem',
      success: 'A munkavállaló sikeresen hozzá lett adva',
      error: 'Hiba történt a meghívó kiküldése közben',
      confirmTitle: 'Megerősítés',
      confirmText: 'A következő telefonszámmal lesz meghívva a diák: {{phoneNumber}}',
    },
    invitedWorkerList: {
      invitedWorkerList: 'Diákok listája',
      emptyMessage:
        '*(Jelenleg még nincsenek meghívott diákok. Kattints a diák meghívása gombra a meghívó kiküldéséhez.)',
      pendingInvite: 'Elfogadásra vár',
      acceptedInvite: 'Visszaigazolt',
      confirmDelete: 'Biztosan törölni szeretnéd ezt a diákot a listádról?',
      resendInvite: {
        success: 'Meghívó újra elküldve',
        error: 'Meghívó elküldése nem sikerült',
      },
      resendButtonLabel: 'Újraküldés',
      removeButtonLabel: 'Eltávolítás',
      removeWorker: {
        success: 'Diák eltávolítva a listáról',
        error: 'Diák eltávolítása a listáról nem sikerült',
      },
    },
  },
  supportScreen: {
    headerTitle: 'Ügyfélszolgálat',
    helperText:
      'Kérdéseiddel, problémáiddal bátran hívj minket az alábbi telefonszámon +36 20 421-8158',
    createMock: 'Create or clear mockdata',
    createMockDataButton: 'Create mockdata',
    clearMockDataButton: 'Clear all account data & logout',
    mockDataCreationSuccess: 'Mock data creation was success',
    mockdataCreationFailed: 'Mock data creation was FAILED',
    mockdataCleanFailed: 'Mock data clear was FAILED',
    mockDataClearSuccess: 'Mock data clear was success',
  },
  notificationScreen: {
    headerTitle: 'Értesítések',
    noNotification: '(Értesítési listád jelenleg üres.)',
  },
  confirmationScreen: {
    title: 'Munkaidő elszámolások',
    approved: 'Jóváhagyva',
    noConfirmation: 'Nincsen jóváhagyásra váró elszámolásod',
    tableHeader: {
      job: 'Munka',
      worker: 'Munkavállaló',
      date: 'Dátum',
      workerAccepted: 'Munkavállaló jóváhagyta-e',
      wage: 'Óradíj (netto)',
      hours: 'Óraszám',
      actions: 'Műveletek',
    },
    confirmationCard: {
      role: 'Munkakör: ',
      workerName: 'Munkavállaló neve: ',
      location: 'Munka helyszíne: ',
      jobStarted: 'Munka kezdete: ',
      hoursWorked: 'Óraszám',
      accept: 'Elfogadás',
      workerConfirmed: 'Munkavállaló jóváhagyott {{hoursWorked}} órát',
      workerNotConfirmed: 'Munkavállaló még nem hagyta jóvá a munkaidőõ elszámolást',
      managerConfirmed: '{{name}} jóváhagyott {{hoursWorked}} órát',
      managerNotConfirmed: 'Manager még nem hagyta jóvá a munkaidő elszámolást',
      status: 'A diák összesen {{wage}} forintot kap {{workingHours}} munkaóráért',
    },
    emptyList: '(Jelenleg nincs jóváhagyásra váró igazolás)',
  },
  financeScreen: {
    account: 'Egyenleg',
    totalTransactions: 'Összes tranzakció: {{transactionCount}}',
    companyBalance: '{{balance}}',
    transactionList: 'Tranzakciók',
    transactionDetails: 'Tranzakció részletei',
    transactionAmount: '{{amount}} HUF',
    transactionDate: '{{date}}',
    balanceAllHistory: 'Összes Tranzakció',
    balanceShowLess: 'Mutass kevesebbet',
    taxAlert: '* A feltüntetett összeg nettó összeg, melyre 27% ÁFÁ-t számítunk fel.',
    youHaveNoTransaction: '(Még nincs megjeleníthető tranzakciód)',
  },
  profileScreen: {
    userName: 'Menedzser teljes neve:',
    userEmail: 'E-mail cím:',
    userPhoneNumber: 'Telefonszám:',
    companyName: 'Cég neve:',
    companyPostCode: 'Irányítószám:',
    companyCity: 'Település neve:',
    companyAddressLine1: 'Közterület, Házszám:',
    companyCountry: 'Ország:',
    companyEmail: 'Céges e-mail cím:',
    companyVatNumber: 'Cég adószáma:',
    companyNumber: 'Cégjegyzékszám:',
    info: 'Menedzser adatlap',
  },
  workerProfile: {
    birthDay: 'Születési dátum',
    hasPaidStudentOrg: 'Van-e érvényes iskolaszövetkezeti tagsága',
    mothersName: 'Anyja neve',
    socialSecurityNumber: 'Személyi igazolvány szám',
    status: 'Státusz',
    studentIdNumber: 'Diákigazolványszám',
    taxNumber: 'Adószám',
    resend: 'Meghívó újraküldése',
    nationality: 'Nemzetiség',
    address: 'Lakcím',
    gender: 'Nem',
    interview: 'Bemutatkozás',
    info: 'Diák adatlap',
    documents: {
      documents: {
        title: 'Dokumentumok',
        error: 'Sikertelen a dokumentumok lekérdezése',
      },
      contracts: {
        title: 'Szerződések',
        error: 'Sikertelen a szerződések lekérdezése',
      },
      uploadDate: 'Feltöltési dátum',
      generationDate: 'Generálási dátum',
      view: 'Megtekintés',
      notAvailable: 'Nem elérhető',
      studentId: 'Diákigazolvány',
      studentStatus: 'Hallgatói jogviszony',
      doctorsPaper: 'Orvosi igazolás',
    },
  },
  notInvitedScreen: {
    helperText: 'Köszönjük a regisztrációd, hamarosan felvesszük veled a kapcsolatot.',
    description:
      'A Della jelenleg zártkörű béta verzióban fut, de ne aggódj, szívesen látunk téged is. Nemsokára jelentkezünk. Azonnali belépést ezen a számon kérhetsz kollégánktól: +36 20 421 8158',
  },
  workerCard: {
    date: 'Állásajánlat kiküldve: {{date}}',
    cancelWorker: 'Lemondás',
    profile: 'Profil',
    jobOfferSent: 'Állásajánlat kiküldve',
    documentReview: 'Iratok ellenőrzése',
    active: 'Aktív',
    inActive: 'Inaktív',
    newApplicant: 'Új jelentkező',
    more: 'további',
  },
  workersPage: {
    filterByName: 'Szűrés név szerint...',
    filterByJob: 'Szűrés munka szerint...',
    filterByPhoneNumber: 'Szűrés telefonszám szerint...',
    filterByStatus: 'Szűrés státusz szerint...',
    includeAll: 'Összes',
    actions: {
      resendInvite: {
        title: 'Meghívó újraküldése',
        success: 'Meghívó sikeresen újraküldve',
        error: 'Hiba történt a meghívó újraküldése közben',
      },
      acceptApplication: {
        title: 'Jelentkezés elfogadása',
        success: 'A munkavállaló jelentkezését sikeresen elfogadtuk',
        error: 'Hiba történt a jelentkezés elfogadása közben',
      },
      declineApplication: {
        title: 'Jelentkezés elutasítása',
        success: 'A munkavállaló jelentkezését sikeresen elutasítottuk',
        error: 'Hiba történt a jelentkezés elutasítása közben',
      },
      removeWorkerFromCompany: {
        title: 'Munkavállaló eltávolítása a cégből',
        success: 'A munkavállaló sikeresen eltávolítva a cégtől',
        error: 'Hiba történt a munkavállaló eltávolítása közben',
      },
      terminateWorker: {
        title: 'Munkavállaló elbocsátása a munkából',
        success: 'A munkavállaló sikeresen elbocsátva lett a munkából',
        error: 'Hiba történt a munkavállaló elbocsátása közben',
      },
      archiveWorker: {
        title: 'Munkavállaló archiválása',
        success: 'A munkavállaló sikeresen archiválva lett',
        error: 'Hiba történt a munkavállaló archiválása közben',
      },
    },
    status: {
      newApplication: 'Új jelentkező',
      pendingOffer: 'Állásajánlat kiküldve',
      active: 'Aktív',
      inActive: 'Inaktív',
      underReview: 'Iratok ellenőrzése',
    },
  },
};
