import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Box } from 'src/components/Box';

import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { WorkerActionsCellProps } from './types';
import { WorkerActionsButton } from 'src/components/WorkerActionsButton';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { useWorkerActions } from 'src/hooks/useWorkerActions';

export const WorkerActionsCell: React.FC<WorkerActionsCellProps> = ({ worker }) => {
  const { t } = useTranslation();

  const {
    resendWorkerInvite,
    acceptApplication,
    declineApplication,
    terminateWorker,
    removeWorkerFromCompany,
    isLoading,
  } = useWorkerActions();

  const jobId = worker?.jobs?.[0]?.id;
  const applicationId = worker?.applications?.[0]?.id;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" flexDirection="row">
        {worker.poolStatus === POOL_STATUS.PENDING_OFFER && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.resendInvite.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              disabled={isLoading}
              onClick={() => resendWorkerInvite({ variables: { workerId: worker.id } })}
            />
            <WorkerActionsButton
              label={t('workersPage.actions.removeWorkerFromCompany.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              disabled={isLoading}
              onClick={() => removeWorkerFromCompany({ variables: { workerId: worker.id } })}
            />
          </Stack>
        )}
        {worker.poolStatus === POOL_STATUS.NEW_APPLICATION && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.acceptApplication.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.INFO}
              disabled={isLoading}
              onClick={() => applicationId && acceptApplication({ variables: { applicationId } })}
            />
            <WorkerActionsButton
              label={t('workersPage.actions.declineApplication.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              disabled={isLoading}
              onClick={() => applicationId && declineApplication({ variables: { applicationId } })}
            />
          </Stack>
        )}
        {worker.poolStatus === POOL_STATUS.ACTIVE && (
          <Stack spacing={2} direction="column" mb={2}>
            <WorkerActionsButton
              label={t('workersPage.actions.terminateWorker.title')}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.ERROR}
              disabled={isLoading}
              onClick={() =>
                jobId && terminateWorker({ variables: { workerId: worker.id, jobId: jobId } })
              }
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};
